import { ReactComponent as TickIcon } from '../../../Assets/Images/svg/tickIcon.svg';
import Tooltip from '../../../Components/Common/ToolTip';
import { GRAY_2 } from '../../../Utils/constants';

type Props = {
  packageName: string;
  price: string;
  tagline?: string;
  pointOne: string;
  pointTwo: string;
  commitments?: string;
  color: string;
  isSelected?: boolean;
};

const PackageContainer = ({
  packageName,
  price,
  tagline,
  pointOne,
  pointTwo,
  commitments,
  color,
  isSelected,
}: Props) => {
  const ContainerContent = (
    <div
      className={`w-full min-w-[320px] sm:w-[320px] md:w-[386px] h-[357px] rounded-[10px] ${
        isSelected ? '' : 'border-[1px] border-gray-2'
      }  flex flex-col items-start p-8 pb-4`}
    >
      <div className="flex gap-2 items-start">
        <p className="text-[22px] poppins-400 text-gray-1">{packageName}</p>
        {packageName !== 'Basic' && <p>*</p>}
      </div>
      <div className="flex gap-2 items-center">
        <p className="text-[42px] font-normal" style={{ color: color }}>
          {price}
        </p>
        {price !== 'Free' && (
          <p className="text-[14px] font-normal mt-3" style={{ color: color }}>
            / Month
          </p>
        )}
      </div>
      <div className="h-20">
        {tagline && <p className="text-[16px] font-semibold text-gray-9 poppins-600">{tagline}</p>}
      </div>
      <div className="flex gap-3 py-3">
        <TickIcon fill={color} />
        <p className="text-[16px] text-gray-9 poppins-400">{pointOne}</p>
      </div>
      <div className="flex gap-3">
        <TickIcon fill={color} />
        <p className="text-[16px] text-gray-9 poppins-400">{pointTwo}</p>
      </div>
      <div className="mt-auto">
        {commitments && (
          <p className="text-[16px] text-left poppins-700 text-gray-9">{commitments}:</p>
        )}
        {commitments && (
          <p className="text-[16px] text-left poppins-400 font-normal text-gray-9">
            No cancellation for the first 90 days
          </p>
        )}
      </div>
    </div>
  );
  return (
    <>
      {color === GRAY_2 ? (
        <Tooltip text="You cannot downgrade your package within 90 days of subscription. Contact support team if you need any help.">
          {ContainerContent}
        </Tooltip>
      ) : (
        ContainerContent
      )}
    </>
  );
};
export default PackageContainer;
