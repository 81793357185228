import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../Store';
import { changeBillingGroupAsync } from '../../../Utils/api';
import { BUSINESS, Role, SubscriptionStatus } from '../../../Utils/constants';
import {
  formatDateString,
  makeFirstLetterCapital,
  validateEmail,
} from '../../../Utils/helperFunctions';
import BillingEmailModal from './BillingEmailModal';
import InfoAttribute from './InfoAtrribute';
import PackagesModal from './PackagesModal';

const InformationSummaryCard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const accountType = useSelector(
    (state: RootState) => state.signup.customer?.customer?.accountType,
  );
  const domain = useSelector((state: RootState) => state.signup.customer?.customer?.domain);
  const gcpSecurityGroup = useSelector(
    (state: RootState) => state.signup.customer?.customer?.gcpSecurityGroup,
  );
  const gcpSubBillingAccountId = useSelector(
    (state: RootState) => state.signup.customer?.customer?.gcpSubBillingAccountId,
  );
  const accountName = useSelector(
    (state: RootState) => state.signup.customer?.customer?.accountName,
  );
  const customer = useSelector((state: RootState) => state.signup.customer?.customer);

  const status = useSelector((state: RootState) => state.signup.customer?.customer?.status);
  const supportPackage = useSelector(
    (state: RootState) => state.signup.customer?.customer?.package,
  );
  const activationDate = useSelector(
    (state: RootState) => state.signup.customer?.customer?.activationDate,
  );
  const subscriptionDate = useSelector(
    (state: RootState) => state.signup.customer?.customer?.subscriptionDate,
  );

  const role = useSelector((state: RootState) => state.admin?.adminUser?.customer?.user?.role);
  const customerId = useSelector((state: RootState) => state.admin?.currentCustomer);

  const [isPackageModalOpen, setIsPackageModalOpen] = useState(false);
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [billingGroup, setBillingGroup] = useState(gcpSecurityGroup ?? '');

  const onSupportPackageUpdateClick = () => {
    setIsPackageModalOpen(true);
  };

  const onBillingPermissionUpdateClick = () => {
    setErrorMessage('');
    setIsBillingModalOpen(true);
  };

  useEffect(() => {
    setBillingGroup(gcpSecurityGroup ?? '');
  }, [gcpSecurityGroup]);

  const handleBillingGroup = () => {
    if (billingGroup && !errorMessage) {
      if (role === Role.ADMIN) {
        dispatch(
          changeBillingGroupAsync(
            billingGroup,
            setIsLoading,
            setIsBillingModalOpen,
            setErrorMessage,
            navigate,
            true,
            customerId,
          ),
        );
      } else {
        dispatch(
          changeBillingGroupAsync(
            billingGroup,
            setIsLoading,
            setIsBillingModalOpen,
            setErrorMessage,
            navigate,
            false,
          ),
        );
      }

      setBillingGroup('');
    } else {
      setErrorMessage('Please enter a valid input.');
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setBillingGroup(email);

    if (!validateEmail(email)) {
      setErrorMessage('Please enter a valid email address.');
    } else {
      setErrorMessage('');
    }
  };

  const updatedBusinessName =
    customer?.businessName && customer.businessName.length > 60
      ? customer.businessName.slice(0, 60) + '...'
      : customer?.businessName ?? '';

  const updatedAccountName =
    accountName && accountName.length > 40 ? accountName.slice(0, 40) + '...' : accountName ?? '';

  const subscriptionDateParsed = subscriptionDate ? new Date(subscriptionDate) : null;
  const createdAtParsed = activationDate ? new Date(activationDate) : null;

  const dateToUse =
    subscriptionDateParsed && createdAtParsed && subscriptionDateParsed < createdAtParsed
      ? activationDate
      : subscriptionDate;

  const taglineText = subscriptionDate ? `Subscribed on ${formatDateString(dateToUse)}` : '';

  return (
    <div className="flex flex-col items-start py-[20px] px-[24px] bg-white-0 border rounded-[10px] border-gray-2 mb-6">
      <div className="flex flex-row w-full">
        <h1 className="poppins-700 text-3xl text-gray-1 whitespace-nowrap overflow-hidden text-ellipsis xxl:max-w-[58vw] max-w-[72vw]">
          {accountType === BUSINESS && makeFirstLetterCapital(updatedBusinessName)}
          {accountType !== BUSINESS && customer?.name}
        </h1>
      </div>

      <div className="flex flex-row gap-x-20 mt-[15px] w-full justify-between">
        {accountType === BUSINESS ? (
          <InfoAttribute title="Domain" value={domain ?? 'Not Available'} />
        ) : null}
        <InfoAttribute
          title="Status"
          value={status ? makeFirstLetterCapital(status) : ''}
          textColor={status === SubscriptionStatus.CANCELLED ? 'text-red-0' : ''}
        />
        <InfoAttribute title="Active Since" value={formatDateString(activationDate)} />
        <InfoAttribute
          title="Support Package"
          value={
            supportPackage ? supportPackage.charAt(0).toUpperCase() + supportPackage.slice(1) : ''
          }
          tagline={taglineText}
          update={onSupportPackageUpdateClick}
          buttonText="Change"
        />

        {isPackageModalOpen && (
          <PackagesModal
            supportPackage={supportPackage}
            setIsPackageModalOpen={setIsPackageModalOpen}
          />
        )}
      </div>
      <div className="flex flex-row gap-x-28  mt-[15px]">
        {accountType === BUSINESS && (
          <InfoAttribute
            title="Billing Permissions"
            value={gcpSecurityGroup ?? ''}
            update={onBillingPermissionUpdateClick}
            buttonText="Update"
          />
        )}

        {isBillingModalOpen && (
          <BillingEmailModal
            setIsBillingModalOpen={setIsBillingModalOpen}
            isLoading={isLoading}
            billingGroup={billingGroup}
            handleEmailChange={handleEmailChange}
            errorMessage={errorMessage}
            handleBillingGroup={handleBillingGroup}
          />
        )}
        <InfoAttribute
          title="Billing Subaccount ID"
          value={updatedAccountName}
          // Update it with backend value later
          subValue={
            gcpSubBillingAccountId
              ? gcpSubBillingAccountId.replace('billingAccounts/', '')
              : 'Not Available'
          }
        />
      </div>
    </div>
  );
};
export default InformationSummaryCard;
