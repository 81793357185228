import React from 'react';
import { useSelector } from 'react-redux';
import SearchIcon from '../../Assets/Images/svg/searchIcon.svg';
import { RootState } from '../../Store';
import { makeFirstLetterCapital } from '../../Utils/helperFunctions';
import DashboardLogout from './DashboardLogout';

type TitleCardProps = {
  title: string;
  icon: React.ComponentType<{ fill?: string }>;
  isSearchBar?: boolean;
  isFilter?: boolean;
  inputValue?: string;
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch?: () => void;
  dropdownValue?: string;
  onDropdownChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

const TitleCard: React.FC<TitleCardProps> = ({
  title,
  icon: IconComponent,
  isSearchBar = false,
  isFilter = false,
  inputValue,
  onInputChange,
  onSearch,
  dropdownValue,
  onDropdownChange,
}) => {
  const email = useSelector((state: RootState) => state.signup.customer?.customer?.user?.email);
  const userName = useSelector(
    (state: RootState) => state.signup.customer?.customer?.user?.firstName,
  );
  const adminEmail = useSelector(
    (state: RootState) => state.admin?.adminUser?.customer?.user?.email,
  );
  const adminUserName = useSelector(
    (state: RootState) => state.admin?.adminUser?.customer?.user?.firstName,
  );

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onSearch) {
      onSearch();
    }
  };

  return (
    <div className="bg-white-0 border rounded-[10px] border-gray-2 px-[33px] py-[5px] mb-[10px] flex flex-row items-center">
      <div className="w-[40px] h-[40px] flex items-center justify-center">
        <IconComponent />
      </div>
      <h1 className=" text-center text-blue-1 text-2xl ml-[21px] poppins-700 whitespace-nowrap mr-2">
        {title}
      </h1>
      {isSearchBar && (
        <div className="w-full flex items-center justify-center gap-2">
          <input
            className="py-2 border border-gray-2 p-2 w-[250px] rounded-[4px]"
            name="searchBar"
            type="text"
            placeholder="Search"
            value={inputValue}
            onChange={onInputChange}
            onKeyPress={handleKeyPress}
            maxLength={150}
          />
          <button onClick={onSearch}>
            <img className="w-[20px] h-[20px]" src={SearchIcon} alt="" />
          </button>
        </div>
      )}

      {isFilter && (
        <div className="w-full flex items-center justify-center gap-2">
          <select
            className="py-2 border border-gray-2 p-2 w-[140px] rounded-[4px]"
            value={dropdownValue}
            onChange={onDropdownChange}
          >
            <option value="active">Active</option>
            <option value="suspended">Suspended</option>
            <option value="cancelled">Cancelled</option>
            <option value="pending-cancellation">Pending Cancellation</option>
          </select>
        </div>
      )}

      <div className="flex justify-end  items-center flex-1">
        <div className="flex flex-col justify-center items-start">
          <h1 className="text-xl text-gray-1 font-semibold">
            {adminUserName
              ? makeFirstLetterCapital(adminUserName)
              : makeFirstLetterCapital(userName)}
          </h1>
          <DashboardLogout email={adminEmail || email} />
        </div>
      </div>
    </div>
  );
};

export default TitleCard;
