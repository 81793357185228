import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ThreeDotsIcon from '../../Assets/Images/svg/threeDotsIcon.svg';
import { AppDispatch, RootState } from '../../Store';
import { SetErrorMessageType, SetIsLoadingType } from '../../Types';
import { deletePaymentMethodData, updatePaymentMethodData } from '../../Utils/api';
import { Card, Role } from '../../Utils/constants';
import { makeFirstLetterCapital } from '../../Utils/helperFunctions';
type CardComponentProps = {
  id: string;
  title: string;
  cardDetails: string;
  expiryDate: string;
  dashedBorder?: boolean;
  setIsLoading: SetIsLoadingType;
  setError: SetErrorMessageType;
  isExpired?: boolean;
};

const CardComponent = ({
  id,
  title,
  cardDetails,
  expiryDate,
  setIsLoading,
  setError,
  dashedBorder,
  isExpired,
}: CardComponentProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const role = useSelector((state: RootState) => state.admin?.adminUser?.customer?.user?.role);
  const customerId = useSelector((state: RootState) => state.admin?.currentCustomer);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      handleClickOutside(event);
    };
    // Add event listener to handle clicks outside the div
    document.addEventListener('mousedown', handleClick);
    return () => {
      // Clean up event listener on component unmount
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const onMenuClick = () => {
    setShowMenu(!showMenu);
  };

  const onRemoveClick = () => {
    if (role === Role.ADMIN) {
      dispatch(deletePaymentMethodData(id, setIsLoading, setError, navigate, true, customerId));
    } else {
      dispatch(deletePaymentMethodData(id, setIsLoading, setError, navigate, false));
    }
  };

  const onMakePrimaryClick = () => {
    if (role === Role.ADMIN) {
      dispatch(
        updatePaymentMethodData(
          id,
          Card.PRIMARY,
          setIsLoading,
          setError,
          navigate,
          true,
          customerId,
        ),
      );
    } else {
      dispatch(updatePaymentMethodData(id, Card.PRIMARY, setIsLoading, setError, navigate, false));
    }
  };

  const onMakeBackupClick = () => {
    if (role === Role.ADMIN) {
      dispatch(
        updatePaymentMethodData(
          id,
          Card.BACKUP,
          setIsLoading,
          setError,
          navigate,
          true,
          customerId,
        ),
      );
    } else {
      dispatch(updatePaymentMethodData(id, Card.BACKUP, setIsLoading, setError, navigate, false));
    }
  };

  return (
    <div
      className={`bg-white-0 border ${dashedBorder ? 'border-dashed' : 'border-solid'} ${
        isExpired ? 'border-red-0' : ' border-gray-3'
      } rounded-xl p-[25px] w-[330px] flex flex-col items-start`}
    >
      <div className="flex flex-row gap-4 w-full relative ">
        <h1
          className={`${
            isExpired ? 'text-red-0' : 'text-gray-1'
          } text-[22px] poppins-500 break-normal w-full text-left whitespace-nowrap`}
        >
          {makeFirstLetterCapital(title)} Card
        </h1>
        {title !== Card.PRIMARY && (
          <button className="flex justify-end w-full" onClick={onMenuClick}>
            <div ref={ref}>
              <img src={ThreeDotsIcon} alt="Three Dots" />
            </div>
          </button>
        )}

        {title !== Card.PRIMARY && showMenu && (
          <div
            id={title}
            ref={ref}
            className="bg-white-0 rounded-xl w-[150px] absolute right-0 top-5 border border-gray-3 px-2.5 py-4 "
          >
            <button className="hover:opacity-[80%]" onClick={onMakePrimaryClick}>
              <h1
                className={`${isExpired ? 'text-red-0' : 'text-gray-1'} opacity-[70%] poppins-400 `}
              >
                Make Primary
              </h1>
              <div className="h-px w-full bg-gray-3 my-2" />
            </button>
            {title !== Card.BACKUP && (
              <button className="hover:opacity-[80%]" onClick={onMakeBackupClick}>
                <h1
                  className={`${
                    isExpired ? 'text-red-0' : 'text-gray-1'
                  } opacity-[70%] poppins-400`}
                >
                  Make Backup
                </h1>
                {title !== Card.PRIMARY && <div className="h-px w-full bg-gray-3 my-2" />}
              </button>
            )}
            <button className="hover:opacity-[80%]" onClick={onRemoveClick}>
              <h1 className="text-gray-1 opacity-[70%] poppins-400">Remove</h1>
            </button>
          </div>
        )}
      </div>

      <div className="h-px w-full bg-gray-3 mt-3" />
      <h1 className={`${isExpired ? 'text-red-0' : 'text-gray-1'}  text-[28px] poppins-500 mt-5`}>
        {makeFirstLetterCapital(cardDetails)}
      </h1>
      <p
        className={`${
          isExpired ? 'text-red-0' : 'text-black-0 opacity-[70%]'
        }  text-lg poppins-500 mt-1`}
      >
        {expiryDate}
      </p>
    </div>
  );
};
export default CardComponent;
