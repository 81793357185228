import { useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DropdownIcon from '../../../Assets/Images/svg/dropdownIcon.svg';
import GreenTickIcon from '../../../Assets/Images/svg/greenTick.svg';
import LogoSection from '../../../Components/Common/LogoSection';
import Spinner from '../../../Components/Common/Spinner';
import Stepper from '../../../Components/Common/Stepper';
import StepperMobile from '../../../Components/Common/StepperMobile';
import { AppDispatch, RootState } from '../../../Store';
import { ContactFieldErrors } from '../../../Types';
import { fetchBusinessInformationAsync, verifyIqamaIdAsync } from '../../../Utils/api';
import {
  INDIVIDUAL,
  SaudiCities,
  SaudiCityProvinces,
  SaudiProvinces,
  textFieldClass,
} from '../../../Utils/constants';
import { splitAccountName } from '../../../Utils/helperFunctions';
import ButtonsSection from '../Common/ButtonsSection';
import SelectField from '../Common/SelectField';

const ContactInformation = () => {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const userDetails =
    useSelector((state: RootState) => state.signup.customer?.customer?.user) ?? {};
  const customerDetails = useSelector((state: RootState) => state.signup.customer?.customer) ?? {};
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [iqamaErrorMessage, setIqamaErrorMessage] = useState('');
  const [isCrVerificationFailed, setIsCrVerificationFailed] = useState(false);
  const [isCrVerifying, setIsCrVerifying] = useState(false);
  const [isCrVerificationStarted, setIsCrVerificationStarted] = useState(false);
  const [isCrVerified, setIsCrVerified] = useState(!!customerDetails?.identificationNumber);
  const [formData, setFormData] = useState({
    identificationNumber: customerDetails?.identificationNumber,
    firstName: userDetails?.firstName,
    lastName: userDetails?.lastName,
    arabicFirstName: userDetails?.arabicFirstName,
    arabicLastName: userDetails?.arabicLastName,
    arabicAddress: customerDetails?.arabicAddress,
    accountName: customerDetails?.accountName ?? 'cntxt-online-ind-nationalidoriqama-',
    contactEmail: customerDetails?.user?.email,
    phone: userDetails?.phone,
    address: customerDetails?.address,
    city: customerDetails?.city ?? '',
    postalCode: customerDetails?.postalCode,
    province: customerDetails?.province ?? '',
  });

  const [fieldErrors, setFieldErrors] = useState<ContactFieldErrors>({
    identificationNumber: false,
    firstName: false,
    lastName: false,
    arabicFirstName: false,
    arabicLastName: false,
    arabicAddress: false,
    accountName: false,
    phone: false,
    address: false,
    city: false,
    postalCode: false,
    province: false,
    contactEmail: false,
  });
  const [isError, setIsError] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (name === 'city') {
      const selectedProvince =
        SaudiCityProvinces.find((city) => city.city === value)?.province ?? '';
      setFormData((prevState) => ({
        ...prevState,
        city: value,
        province: selectedProvince,
      }));
    } else if (name === 'province') {
      setFormData((prevState) => ({
        ...prevState,
        city: '',
        [name]: value,
      }));
    } else {
      const sanitizedValue = value.replace(/[\u0600-\u06FF]/g, '');
      setFormData((prevState) => ({
        ...prevState,
        [name]: sanitizedValue,
      }));
    }
    console.log(formData);
  };

  const renderCrVerificationStatus = () => {
    console.log('cr verify=', isCrVerified);
    if (isCrVerifying) {
      return <Spinner adjustContainerHeight />;
    } else if (!isCrVerificationStarted && !isCrVerified) {
      return 'Verify';
    } else if (isCrVerified) {
      return <img className="w-7 h-7" src={GreenTickIcon} alt="Verified" />;
    }
  };

  const verifyCrNumber = () => {
    const crNumber = formData.identificationNumber ?? '';
    if (crNumber) {
      setIsCrVerificationStarted(true);
      dispatch(
        verifyIqamaIdAsync(
          formData.identificationNumber ?? '',
          setIsCrVerified,
          setIsCrVerifying,
          setIsCrVerificationFailed,
          navigate,
          setIqamaErrorMessage,
        ),
      );
      setIsCrVerificationStarted(false);
    }
  };

  const verifyIqamaIdOnFocusOut = () => {
    const crNumber = formData.identificationNumber ?? '';
    if (crNumber.length === 10) {
      verifyCrNumber();
    }
  };

  const handleArabicFieldsChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    const sanitizedValue = value.replace(/[^\u0600-\u06FF0-9\s.-]/g, '');

    setFormData((prevState) => ({
      ...prevState,
      [name]: sanitizedValue,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors: ContactFieldErrors = {
      identificationNumber: !formData.identificationNumber,
      firstName: !formData.firstName,
      lastName: !formData.lastName,
      arabicFirstName: !formData.arabicFirstName,
      arabicLastName: !formData.arabicLastName,
      arabicAddress: !formData.arabicAddress,
      accountName: !formData.accountName,
      phone: !formData.phone || !isValidPhoneNumber(formData.phone),
      address: !formData.address,
      city: !formData.city,
      postalCode: !formData.postalCode,
      province: !formData.province,
      contactEmail: !formData.contactEmail,
    };
    setFieldErrors(errors);

    const hasErrors = Object.values(errors).some((error) => error);
    setIsError(hasErrors);

    if (!hasErrors && formData.postalCode?.toString().length === 5) {
      dispatch(fetchBusinessInformationAsync(formData, setIsLoading, setErrorMessage, navigate));
    }
  };

  function getErrorMessage(isError: boolean, errorMessage: string): string {
    let finalErrorMessage = '';
    if (errorMessage) {
      finalErrorMessage = errorMessage;
    }
    if (isError) {
      finalErrorMessage = 'Please fill all required fields';
    }
    return finalErrorMessage;
  }

  const getInputClass = (fieldName: keyof ContactFieldErrors): string => {
    return `${textFieldClass} ${fieldErrors[fieldName] ? 'border-red-0' : ''}`;
  };

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.substring(0, 60);
    let firstName = inputValue.replace(/[\u0600-\u06FF]/g, '');
    const accountNamePrefix = 'cntxt-online-ind-';

    const maxLength =
      60 - (accountNamePrefix.length + (formData.identificationNumber ?? '').length + 1);
    firstName = firstName.substring(0, maxLength);

    setFormData((prevState) => ({
      ...prevState,
      firstName: firstName,
      accountName: `${accountNamePrefix}${formData.identificationNumber}-${firstName}`
        ?.toLowerCase()
        ?.replaceAll(' ', '-'),
    }));
  };

  const onAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.substring(0, 60);
    const sanitizedValue = inputValue.replace(/[\u0600-\u06FF]/g, '');
    const accountName = splitAccountName(formData.accountName, false);
    setFormData((prevState) => ({
      ...prevState,
      accountName: `${accountName?.substringBefore}${sanitizedValue}`
        ?.toLowerCase()
        ?.replaceAll(' ', '-'),
    }));
  };

  const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.value;
    const accountNamePrefix = 'cntxt-online-ind-';
    const truncatedFirstName = formData.firstName.substring(
      0,
      60 - (accountNamePrefix.length + id.length + 1),
    );

    setFormData((prevState) => ({
      ...prevState,
      identificationNumber: id,
      accountName: `${accountNamePrefix}${id}-${truncatedFirstName}`
        ?.toLowerCase()
        ?.replaceAll(' ', '-'),
    }));
  };

  const handlePhoneChange = (number: string) => {
    setFormData((prevState) => ({
      ...prevState,
      phone: number,
    }));
  };

  return (
    <div className="w-full h-full flex items-stretch lg:items-center justify-center">
      {isLoading ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="border-[1px] border-gray-3 rounded-lg py-4 px-8 bg-white-0 min-h-[680px] w-[90vw] lg:w-[1400px] overflow-auto flex flex-col justify-between">
            <LogoSection />
            <Stepper type={INDIVIDUAL} currentStep={1} />
            <StepperMobile
              stepNumber={1}
              stepName="Contact Information"
              nextStep="Support Package"
              totalSteps={3}
            />
            <div className="flex flex-col md:flex-row w-full gap-12 mb-4">
              <div className="w-[90%]">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left justify-between text-sm poppins-500 text-gray-1">
                    National ID / Iqama
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <div className="relative flex">
                  <input
                    className={`pr-14 py-4 [&::-webkit-outer-spin-button]:hidden [&::-webkit-inner-spin-button]:hidden poppins-500 ${
                      isCrVerificationFailed && 'border-red-0'
                    } ${!isCrVerificationFailed && 'border-gray-2'} ${getInputClass(
                      'identificationNumber',
                    )} [&::-webkit-outer-spin-button]:hidden [&::-webkit-inner-spin-button]:hidden`}
                    type="number"
                    placeholder="National ID / Iqama"
                    onChange={handleIdChange}
                    onBlur={verifyIqamaIdOnFocusOut}
                    name="identificationNumber"
                    style={{ MozAppearance: 'textfield' }}
                    value={formData.identificationNumber ?? ''}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.target.value = e.target.value.slice(0, 10);
                      setIsCrVerified(false);
                    }}
                  />
                  <button
                    className={`w-16 h-10 text-gray-1 pt-4 underline absolute top-0 right-0 text-[16px] underline-offset-4 font-normal ${
                      formData.identificationNumber?.length !== 10 && 'opacity-30'
                    } ${
                      formData.identificationNumber?.length === 10 && 'opacity-70 cursor-pointer'
                    }`}
                    type="button"
                    onClick={() => !isCrVerified && verifyCrNumber()}
                    disabled={formData.identificationNumber?.length !== 10}
                  >
                    <div className="flex justify-center items-center">
                      {renderCrVerificationStatus()}
                    </div>
                  </button>

                  {formData.identificationNumber && isCrVerificationFailed && (
                    <p className="text-red-0 text-xs absolute top-[105%] right-0 left-0">
                      {iqamaErrorMessage}
                    </p>
                  )}
                </div>
              </div>
              <div className="w-[90%]">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left text-sm poppins-500 text-gray-1">First Name</h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className={`py-4 poppins-500 ${getInputClass('firstName')}`}
                  type="text"
                  placeholder="First Name"
                  onChange={handleFirstNameChange}
                  name="firstName"
                  value={formData.firstName || ''}
                  maxLength={40}
                />
              </div>
              <div className="w-[90%]" dir="rtl" lang="ar">
                <div className="flex gap-1 items-center">
                  <h3
                    style={{ fontFamily: "'Amiri', serif" }}
                    className="text-right text-sm font-medium leading-loose text-gray-1"
                  >
                    الاسم الأول
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className={`py-4 ${getInputClass('arabicFirstName')}`}
                  style={{ fontFamily: "'Amiri', serif" }}
                  type="text"
                  placeholder="الاسم الأول"
                  onChange={handleArabicFieldsChange}
                  name="arabicFirstName"
                  value={formData.arabicFirstName || ''}
                  maxLength={40}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-12  mb-4">
              <div className="w-[90%]">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left text-sm poppins-500 text-gray-1">Account Name</h3>
                  <p className="text-red-0">*</p>
                </div>
                <div className="relative flex">
                  <div
                    className={`flex items-center relative ${getInputClass(
                      'accountName',
                    )} focus-within:border-transparent focus-within:outline-none focus-within:ring-2 focus-within:ring-[#2563eb] relative [&_input]:outline-none`}
                  >
                    <div className="flex w-full text-left md:whitespace-nowrap overflow-auto [&::-webkit-scrollbar]:hidden">
                      <span className="w-fit pt-2 poppins-500 text-gray-5 opacity-80 text-nowrap">
                        {splitAccountName(formData.accountName || '', false)?.substringBefore}
                      </span>
                      <input
                        className="w-full overflow-auto poppins-500 py-2"
                        name="accountName"
                        type="text"
                        placeholder="First Name"
                        value={splitAccountName(formData.accountName || '', false)?.substringAfter}
                        onChange={onAccountNameChange}
                        maxLength={150}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[90%]">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left text-sm poppins-500 text-gray-1">Last Name</h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className={`poppins-500 py-4 ${getInputClass('lastName')}`}
                  type="text"
                  placeholder="Last Name"
                  onChange={handleChange}
                  name="lastName"
                  value={formData.lastName || ''}
                  maxLength={40}
                />
              </div>
              <div className="w-[90%]" dir="rtl" lang="ar">
                <div className="flex gap-1 items-center">
                  <h3
                    style={{ fontFamily: "'Amiri', serif" }}
                    className="text-right justify-between text-sm font-medium leading-loose text-gray-1"
                  >
                    اسم العائلة
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className={`py-4 ${getInputClass('arabicLastName')}`}
                  style={{ fontFamily: "'Amiri', serif" }}
                  type="text"
                  placeholder="اسم العائلة"
                  onChange={handleArabicFieldsChange}
                  name="arabicLastName"
                  value={formData.arabicLastName || ''}
                  maxLength={40}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-12  mb-4">
              <div className="w-[90%] poppins-500">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left justify-between text-sm font-medium leading-loose text-gray-1">
                    Email
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className={`py-4 ${textFieldClass}`}
                  type="email"
                  placeholder="Email"
                  onChange={handleChange}
                  name="contactEmail"
                  maxLength={70}
                  value={formData.contactEmail}
                  disabled={true}
                />
              </div>
              <div className="w-[90%]  mb-4 md:mb-0 poppins-500">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left text-sm font-medium leading-loose text-gray-1">Phone</h3>
                  <p className="text-red-0">*</p>
                </div>
                <div
                  className={`${getInputClass(
                    'phone',
                  )} focus-within:outline-none py-4 focus-within:ring-2 focus-within:border-none focus-within:ring-[#2563eb] relative [&_input]:outline-none [&>div>div>.PhoneInputCountryIcon]:h-5 [&>div>div>.PhoneInputCountryIcon]:w-8`}
                >
                  <PhoneInput
                    defaultCountry="SA"
                    name="phone"
                    placeholder="Phone number"
                    value={formData.phone ?? ''}
                    onChange={handlePhoneChange}
                    limitMaxLength
                  />
                  {formData.phone && isValidPhoneNumber(formData.phone) === false && (
                    <p className="text-red-0 text-xs absolute top-[105%] right-0 left-0">
                      Invalid Phone Number
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-8  mb-4">
              <div className="w-[90%] poppins-500">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left justify-between text-sm font-medium leading-loose text-gray-1">
                    Street Address
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className={`py-4 ${getInputClass('address')}`}
                  type="text"
                  placeholder="Street Address"
                  onChange={handleChange}
                  name="address"
                  value={formData.address ?? ''}
                  maxLength={150}
                />
              </div>
              <div className="w-[90%]" dir="rtl" lang="ar">
                <div className="flex gap-1 items-center">
                  <h3
                    style={{ fontFamily: "'Amiri', serif" }}
                    className="text-right text-sm font-medium leading-loose text-gray-1"
                  >
                    عنوان الشارع
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  style={{ fontFamily: "'Amiri', serif" }}
                  className={`py-4 ${getInputClass('arabicAddress')}`}
                  type="text"
                  placeholder="عنوان الشارع"
                  onChange={handleArabicFieldsChange}
                  name="arabicAddress"
                  value={formData.arabicAddress ?? ''}
                  maxLength={120}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-8  mb-4">
              <div className="w-[90%] poppins-500">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left justify-between text-sm font-medium leading-loose text-gray-1">
                    City
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <SelectField
                  className={`py-4 ${getInputClass('city')}`}
                  options={SaudiCities}
                  value={formData.city || ''}
                  onChange={handleChange}
                  name="city"
                  dropdownIcon={DropdownIcon}
                />
              </div>
              <div className="w-[90%] poppins-500">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left text-sm font-medium leading-loose text-gray-1">
                    Postal Code
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className={
                    getInputClass('postalCode') +
                    ` [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none py-4 ${
                      formData.postalCode?.toString().length !== 0 &&
                      formData.postalCode?.toString().length !== 5 &&
                      formData.postalCode !== null
                        ? 'border-red-0'
                        : 'border-gray-2'
                    }`
                  }
                  type="number"
                  placeholder="Postal Code"
                  style={{ MozAppearance: 'textfield' }}
                  onChange={handleChange}
                  name="postalCode"
                  value={formData.postalCode ?? ''}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.target.value = e.target.value.slice(0, 5);
                  }}
                />
                {formData.postalCode?.toString().length !== 0 &&
                  formData.postalCode?.toString().length !== 5 &&
                  formData.postalCode !== null && (
                    <p className="text-red-0 text-xs">Postal code should have 5 digits</p>
                  )}
              </div>
              <div className="w-[90%] poppins-500">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left justify-between text-sm font-medium leading-loose text-gray-1">
                    Province
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <SelectField
                  className={`py-4 ${getInputClass('province')}`}
                  options={SaudiProvinces}
                  value={formData.province}
                  onChange={handleChange}
                  name="province"
                  dropdownIcon={DropdownIcon}
                />
              </div>
              <div className="w-[90%] poppins-500">
                <div className="flex gap-1 items-center">
                  <h3 className="text-left text-sm font-medium leading-loose text-gray-1">
                    Country
                  </h3>
                  <p className="text-red-0">*</p>
                </div>
                <input
                  className="flex w-full h-[44px] px-2 py-7 items-center rounded-[4px] bg-gray-4 text-gray-1 text-opacity-70"
                  type="text"
                  placeholder="Saudi Arabia"
                  disabled
                  maxLength={40}
                />
              </div>
            </div>

            <ButtonsSection
              errorMessage={getErrorMessage(isError, errorMessage)}
              isBackButton={false}
              isNextButton={true}
              isSubmitButton={true}
            />
          </div>
        </form>
      )}
    </div>
  );
};
export default ContactInformation;
