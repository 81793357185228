import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../Components/Common/CustomButton';
import Modal from '../../../Components/Common/Modal';
import Spinner from '../../../Components/Common/Spinner';
import { AppDispatch, RootState } from '../../../Store';
import { changeSupportPackageAsync } from '../../../Utils/api';
import {
  BASIC,
  ButtonColor,
  ENHANCED,
  GRAY_2,
  Role,
  STANDARD,
  SUB_DURATION,
  supportPackages,
} from '../../../Utils/constants';
import { isWithinDays } from '../../../Utils/helperFunctions';
import PackageContainer from '../../Signup/Common/PackageContainer';
import PackageChangeModal from '../Modals/PackageChangeModal';

type ModalTypes = {
  supportPackage: string | null;
  setIsPackageModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const isPackageDisabled = (supportPackage: string, packageName: string) => {
  if (supportPackage === STANDARD.toLowerCase() && packageName === BASIC) {
    return true;
  }
  if (
    supportPackage === ENHANCED.toLowerCase() &&
    (packageName === BASIC || packageName === STANDARD)
  ) {
    return true;
  }
  return false;
};

const PackagesModal = ({ supportPackage, setIsPackageModalOpen }: ModalTypes) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [selectedPackageName, setSelectedPackageName] = useState(
    supportPackage ?? BASIC.toLowerCase(),
  );
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isPackageChangeModalOpen, setIsPackageChangeModalOpen] = useState(false);

  const role = useSelector((state: RootState) => state.admin?.adminUser?.customer?.user?.role);
  const activationDate = useSelector(
    (state: RootState) => state.signup?.customer?.customer?.subscriptionDate,
  );
  const customerId = useSelector((state: RootState) => state.admin?.currentCustomer);

  const updatedSupportPackages = supportPackages.map((value) => ({
    disabled: isWithinDays(activationDate || '', SUB_DURATION)
      ? isPackageDisabled(supportPackage ?? BASIC, value.packageName)
      : false,
    ...value,
  }));
  const handlePackageChange = () => {
    setIsPackageChangeModalOpen(true);
  };

  const handleNo = () => {
    setIsPackageChangeModalOpen(false);
  };

  const handlePackageUpdate = () => {
    if (selectedPackageName !== supportPackage) {
      if (role === Role.ADMIN) {
        dispatch(
          changeSupportPackageAsync(
            selectedPackageName,
            setIsLoading,
            setIsPackageModalOpen,
            setErrorMessage,
            navigate,
            true,
            customerId,
          ),
        );
      } else {
        dispatch(
          changeSupportPackageAsync(
            selectedPackageName,
            setIsLoading,
            setIsPackageModalOpen,
            setErrorMessage,
            navigate,
            false,
          ),
        );
      }
    }
    setIsPackageChangeModalOpen(false);
  };

  return (
    <>
      <Modal title="Update the support package" setIsModalOpen={setIsPackageModalOpen}>
        <div className="flex flex-col gap-3">
          {isLoading ? (
            <Spinner adjustContainerHeight />
          ) : (
            <div className="flex flex-col md:flex-row gap-8 justify-center items-center pt-4 md:pt-8">
              {updatedSupportPackages.map((value) => (
                <button
                  disabled={value.disabled}
                  key={value.packageName}
                  onClick={() => setSelectedPackageName(value.packageName.toLowerCase())}
                  className={`${value.disabled ? 'bg-gray-10 cursor-default' : ''} ${
                    selectedPackageName === value.packageName.toLowerCase()
                      ? 'translate-y-[-16px] border-2 rounded-[10px] transition duration-500'
                      : ''
                  }`}
                  style={{
                    borderColor:
                      selectedPackageName === value.packageName.toLowerCase()
                        ? value.color
                        : 'transparent', // Use the color prop for the border
                  }}
                >
                  <PackageContainer
                    packageName={value.packageName}
                    price={value.price}
                    tagline={value.tagline}
                    pointOne={value.pointOne}
                    pointTwo={value.pointTwo}
                    commitments={value.commitments}
                    color={value.disabled ? GRAY_2 : value.color}
                    isSelected={selectedPackageName === value.packageName.toLowerCase()}
                  />
                </button>
              ))}
            </div>
          )}
          {errorMessage ? (
            <p className="text-[14px] text-red-0 max-w-[300px]">{errorMessage}</p>
          ) : null}
          <div className="flex justify-end">
            <CustomButton
              onClick={() => handlePackageChange()}
              disabled={selectedPackageName === supportPackage}
              colorClass={ButtonColor.DARK_BLUE}
            >
              Update
            </CustomButton>
          </div>
        </div>
      </Modal>

      {isPackageChangeModalOpen && (
        <PackageChangeModal
          setIsModalOpen={setIsPackageChangeModalOpen}
          handleYes={handlePackageUpdate}
          handleNo={handleNo}
        />
      )}
    </>
  );
};

export default PackagesModal;
