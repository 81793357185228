import { ReactNode } from 'react';
import LeftArrowHeadIcon from '../../Assets/Images/svg/leftArrowHeadIcon.svg';
import RightArrowHeadIcon from '../../Assets/Images/svg/rightArrowHeadIcon.svg';
import { toCamelCase } from '../../Utils/helperFunctions';
import Spinner from './Spinner';

type TableData = { [key: string]: string | number };

type DataTableProps = {
  tableHeader: string[];
  data: TableData[];
  totalPages: number;
  currentPage: number;
  children?: ReactNode;
  height: string;
  adjustTopBorder?: boolean;
  removePaginationBorder?: boolean;
  highlightTableColumnValue?: string;
  highlightCondition?: string;
  highlightedCellStyling?: string;
  alternateHighlightedCellStyling?: string;
  onButtonClick?: (data: number | string) => void;
  onLeftArrowClick?: () => void;
  onRightArrowClick?: () => void;
  onPageNumberClick?: (pageNumber: number) => void;
  selectedActionItem?: string | number;
  isDataLoading?: boolean;
  isLoading?: boolean;
  errorMessage?: string;
  actionName?: string;
  sortField?: string | null;
  sortOrder?: string | null;
  onSort?: (column: string, direction: 'asc' | 'desc') => void;
};

const DataTable = ({
  tableHeader,
  data,
  totalPages,
  currentPage,
  children,
  adjustTopBorder,
  height,
  highlightTableColumnValue,
  highlightCondition,
  highlightedCellStyling,
  alternateHighlightedCellStyling,
  removePaginationBorder,
  onButtonClick,
  onLeftArrowClick,
  onRightArrowClick,
  onPageNumberClick,
  selectedActionItem,
  isDataLoading,
  isLoading,
  errorMessage,
  actionName,
  onSort,
  sortField,
  sortOrder,
}: DataTableProps) => {
  const onActionButtonClick = (action: number | string) => {
    if (onButtonClick) {
      onButtonClick(action);
    }
  };

  const handleSort = (column: string, direction: 'asc' | 'desc') => {
    if (onSort) {
      onSort(column, direction);
    }
  };

  const startIndex = Math.max(0, currentPage - 2);
  const endIndex = Math.min(totalPages - 1, startIndex + 3);
  console.log(tableHeader);
  console.log(sortField);
  console.log(toCamelCase('Created_At'));
  const tableHeaderSection = (
    <thead className="px-[25px] py-3 sticky top-0 z-10 bg-white-0">
      <tr>
        {tableHeader.map((heading) => (
          <th
            key={heading}
            className="border-b border-gray-3 poppins-500 text-gray-1 opacity-[70%] text-sm  text-right px-[25px] py-5"
          >
            <div className="inline-flex items-center">
              {heading.replace(/_/g, ' ')}
              {(heading === 'Name' || heading === 'Created_At') && (
                <div className="ml-2 flex">
                  <button
                    onClick={() => handleSort(heading.toLowerCase(), 'desc')}
                    className={`ml-2 ${
                      sortField?.toLowerCase() === heading?.replaceAll('_', '')?.toLowerCase() &&
                      sortOrder === 'desc'
                        ? 'text-blue-1'
                        : ''
                    }`}
                  >
                    ↑
                  </button>
                  <button
                    onClick={() => handleSort(heading.toLowerCase(), 'asc')}
                    className={`ml-2 ${
                      sortField?.toLowerCase() === heading?.replaceAll('_', '')?.toLowerCase() &&
                      sortOrder === 'asc'
                        ? 'text-blue-1'
                        : ''
                    }`}
                  >
                    ↓
                  </button>
                </div>
              )}
            </div>
          </th>
        ))}
        <th className=" poppins-500 text-gray-1 opacity-[70%] text-sm px-[25px] border-b border-gray-3 ">
          {actionName ?? 'Action'}
        </th>
      </tr>
    </thead>
  );

  const getClassName = (
    header: string,
    row: TableData,
    highlightedCondition?: string,
    highlightTableColumnValue?: string,
  ) => {
    const bgColor =
      row[header.toLowerCase()] === highlightCondition
        ? highlightedCellStyling
        : alternateHighlightedCellStyling;
    return highlightTableColumnValue === header.toLowerCase() ? `${bgColor} ` : 'bg-transparent';
  };

  const tableBodySection = (
    <tbody>
      {data.map((row, index) => (
        <tr key={data[index].toString()} className="border-b border-gray-3">
          {tableHeader.map((header) => (
            <td
              key={header}
              className={`${
                highlightTableColumnValue === header.toLowerCase()
                  ? ` text-sm`
                  : 'text-gray-1 text-[15px]'
              } border-b border-gray-3 pl-[20px] px-[20px] py-3 poppins-300 max-w-[200px] `}
            >
              <div className="flex justify-end">
                <div
                  className={getClassName(
                    header,
                    row,
                    highlightCondition,
                    highlightTableColumnValue,
                  )}
                >
                  {row[header.toLowerCase()] ? row[header.toLowerCase()] : '-'}
                </div>
              </div>
            </td>
          ))}
          <td className="py-3 flex justify-center items-center ">
            {selectedActionItem === row['action'] && isLoading ? (
              <Spinner adjustContainerHeight />
            ) : (
              <button
                onClick={() => onActionButtonClick(row['action'])}
                disabled={row['action'] === '' || !row['action']}
                className={`${row['action'] === '' || !row['action'] ? 'opacity-50' : ''} `}
              >
                {children}
              </button>
            )}
          </td>
        </tr>
      ))}
    </tbody>
  );

  const tablePagination = (
    <div
      className={`${
        removePaginationBorder ? '' : 'border-t border-gray-3'
      } bg-white-0 w-full absolute bottom-0 px-3.5 py-3 flex flex-row justify-end`}
    >
      {totalPages === 1 ? (
        <div
          className={
            'bg-blue-1 w-[24px] h-[24px] rounded-[5px] flex justify-center items-center ml-3'
          }
        >
          <p className="text-sm font-semibold text-white-0">1</p>
        </div>
      ) : (
        <>
          {currentPage > 0 && (
            <button
              onClick={onLeftArrowClick}
              className={` bg-gray-3 w-[24px] h-[24px] rounded-[5px] flex justify-center items-center ml-3`}
            >
              <img src={LeftArrowHeadIcon} alt="Left Arrow Icon"></img>
            </button>
          )}
          {Array.from({ length: endIndex - startIndex + 1 }, (_, index) => (
            <div
              key={startIndex + index}
              className={`${
                currentPage == startIndex + index ? 'bg-blue-1' : 'bg-gray-3'
              } w-[24px] h-[24px] rounded-[5px] flex justify-center items-center ml-3`}
            >
              <button
                key={startIndex + index}
                className={`text-sm font-semibold ${
                  currentPage == startIndex + index ? 'text-white-0' : 'text-gray-1 opacity-[70%]'
                }`}
                onClick={() => onPageNumberClick && onPageNumberClick(startIndex + index)}
              >
                {startIndex + index + 1}
              </button>
            </div>
          ))}
          {currentPage < totalPages - 1 && (
            <button
              onClick={onRightArrowClick}
              className={` bg-gray-3 w-[24px] h-[24px] rounded-[5px] flex justify-center items-center ml-3`}
            >
              <img src={RightArrowHeadIcon} alt="Right Arrow Icon"></img>
            </button>
          )}
        </>
      )}
    </div>
  );

  return (
    <div
      className={`bg-white-0 gap-1 relative ${height} border overflow-hidden border-gray-3 ${
        adjustTopBorder ? 'rounded-b-lg border-t-gray-8' : 'rounded-lg'
      } `}
    >
      <div className="overflow-y-auto h-[570px] scrollbar-hide">
        <table className="auto bg-white-0 w-[100%]">
          {tableHeaderSection}
          {isDataLoading && (
            <div className="absolute top-2/4 right-2/4">
              <Spinner adjustContainerHeight />
            </div>
          )}
          {!isDataLoading && tableBodySection}
          {errorMessage && !isDataLoading && (
            <div className="absolute top-2/4 right-2/4">
              <p className="text-[16px] text-center text-red-0 ">{errorMessage}</p>
            </div>
          )}
        </table>
      </div>
      {tablePagination}
    </div>
  );
};
export default DataTable;
